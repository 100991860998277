import { action, makeObservable, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NavigateFunction, Params } from "react-router-dom";
import Store from "../libs/store";
import Popup from "../ui/Popup";
import Table from "../ui/Table";
import { withRouter } from "../utils/react_utils";
import { timeout } from "../utils/utils";
import Translator from "./parts/Translator";

@observer
class Translations extends React.Component<{ store: Store; router: { location: Location; navigate: NavigateFunction; params: Params<string> } }> {
    @observable
    translations: any[] = [];
    @observable
    locale = "";

    @observable
    current: any = {};
    @observable
    toClear: any = {};
    @observable
    showEditPopup = false;

    @observable
    showDeletePopup = false;

    @observable
    hasError = false;
    @observable
    errorMessage = "";

    constructor(props: any) {
        super(props);

        makeObservable(this);

        this.init();
    }

    @action
    init = async () => {
        try {
            const locale = this.props.router.params.locale;

            if (locale) {
                this.locale = locale;
                this.translations = (await this.props.store.api.getTransaltions(locale)).data;
            }
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                await this.props.store.logout();
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }
            this.hasError = true;
            await timeout(2000);
            this.hasError = false;
        }
    };

    @action
    editOnOpenPopup = async (data: any) => {
        this.current = data;
        this.showEditPopup = true;
        this.toClear = {};
    };

    @action
    saveTrasnlation = async () => {
        try {
            for (let i = 1; i <= 4; i++) {
                console.log(this.toClear[i - 1])
                if (this.toClear[i - 1]) {
                    this.current[`targetVariant${i}`] = null;
                }
            }

            await this.props.store.api.update(this.locale, this.current.srcSingularHash, this.current.srcPluralHash, {
                targetVariant1: this.current.targetVariant1,
                targetVariant2: this.current.targetVariant2,
                targetVariant3: this.current.targetVariant3,
                targetVariant4: this.current.targetVariant4,
            });

            this.showEditPopup = false;

            let c1 = 0;
            let c2 = 0;
            for (let i = 1; i <= 4; i++) {
                if (this.current[`targetVariant${i}`] !== null) {
                    c1++;
                }
                if (this.current[`targetVariant${i}`] !== null && this.current[`targetVariant${i}`].length === 0) {
                    c2++;
                }
            }

            if (c1 === c2) {
                this.errorMessage = "Setting transaltion to empty string will to remove translation from db please use delete button!";

                this.hasError = true;
                await timeout(2000);
                this.hasError = false;
            }

            console.log(toJS(this.current), toJS(this.toClear), c1, c2);

            await this.init();
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                await this.props.store.logout();
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }
            this.hasError = true;
            await timeout(2000);
            this.hasError = false;
        }
    };

    @action
    deleteOnOpenPopup = async (data: any) => {
        this.current = data;
        this.showDeletePopup = true;
    };

    @action
    deleteTrasnlation = async () => {
        try {
            await this.props.store.api.delete(this.locale, this.current.srcSingularHash, this.current.srcPluralHash);

            this.showDeletePopup = false;

            await this.init();
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                await this.props.store.logout();
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }
            this.hasError = true;
            await timeout(2000);
            this.hasError = false;
        }
    };

    render(): React.ReactNode {
        return (
            <>
                <Row>
                    <Col>
                        <h2>Translations</h2>

                        <Alert show={this.hasError} variant="danger">
                            {this.errorMessage}
                        </Alert>

                        <Link style={{ "paddingBottom": "20px", display: "block" }} to={"/languages"}>
                            Back to langauges
                        </Link>
                        <Table
                            columns={[
                                {
                                    Header: "srcSingular",
                                    accessor: "srcSingular",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "Is translated",
                                    accessor: "targetVariant1",
                                    disableSortBy: false,
                                    actions: (row: any) => {
                                        return row.original.targetVariant1 !== null ? "Translated" : "Not translated";
                                    },
                                },
                                {
                                    Header: "firstSeenUrl",
                                    accessor: "firstSeenUrl",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "lastEditor",
                                    accessor: "lastEditor",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "Actions",
                                    accessor: "action",
                                    disableSortBy: true,
                                    actions: (row: any) => {
                                        return (
                                            <div className="btn-group btn-group-sm">
                                                <button type="button" className="btn btn-primary btn-sm" onClick={() => this.editOnOpenPopup(row.original)}>
                                                    Edit
                                                </button>
                                                <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.deleteOnOpenPopup(row.original)}>
                                                    Delete
                                                </button>
                                            </div>
                                        );
                                    },
                                },
                            ]}
                            data={this.translations}
                        />
                    </Col>
                </Row>
                <Popup title={`Edit translation`} onClose={() => (this.showEditPopup = false)} onSave={this.saveTrasnlation} show={this.showEditPopup}>
                    <Translator store={this.props.store} translation={this.current} toClear={this.toClear} />
                </Popup>

                <Popup title={`Delete translation`} onClose={() => (this.showDeletePopup = false)} onSave={this.deleteTrasnlation} show={this.showDeletePopup}>
                    Confirm deletion of translation:
                    <p>{this.current.srcSingular}</p>
                </Popup>
            </>
        );
    }
}

export default withRouter(Translations);

import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Store from "../libs/store";
import Table from "../ui/Table";
import { timeout } from "../utils/utils";

@observer
export default class Languages extends React.Component<{ store: Store }> {
    @observable
    langauges: any[] = [];
    @observable
    hasError = false;
    @observable
    errorMessage = "";

    constructor(props: any) {
        super(props);

        makeObservable(this);

        this.init();
    }

    @action
    init = async () => {
        try {
            this.langauges = (await this.props.store.api.getLanguages()).data;
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                await this.props.store.logout();
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }
            this.hasError = true;
            await timeout(2000);
            this.hasError = false;
        }
    };

    render(): React.ReactNode {
        return (
            <Row>
                <Col>
                    <h2>Languages</h2>

                    <Alert show={this.hasError} variant="danger">
                        {this.errorMessage}
                    </Alert>

                    <Table
                        columns={[
                            {
                                Header: "Locale",
                                accessor: "locale",
                                disableSortBy: false,
                            },
                            {
                                Header: "Name",
                                accessor: "name",
                                disableSortBy: false,
                            },
                            {
                                Header: "Total",
                                accessor: "total",
                                disableSortBy: false,
                            },
                            {
                                Header: "Translated",
                                accessor: "translated",
                                disableSortBy: false,
                            },
                            {
                                Header: "Untranslated",
                                accessor: "untranslated",
                                disableSortBy: false,
                            },
                            {
                                Header: "Actions",
                                accessor: "action",
                                disableSortBy: true,
                                actions: (row: any) => {
                                    return (
                                        <div className="btn-group btn-group-sm">
                                            <Link to={`/languages/translations/${row.original.locale}`}>View</Link>
                                        </div>
                                    );
                                },
                            },
                        ]}
                        data={this.langauges}
                    />
                </Col>
            </Row>
        );
    }
}

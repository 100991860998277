import { action } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Form } from "react-bootstrap";
import Store from "../../libs/store";

const plurals = ["targetVariant1", "targetVariant2", "targetVariant3", "targetVariant4"];

@observer
export default class Translator extends React.Component<{ store: Store; translation: any; toClear: any }> {
    render(): React.ReactNode {
        const translators = [];
        let i = 0;
        for (const plural of this.props.translation.plurals) {
            translators.push(
                <>
                    <Form.Group className="mb-3" key={plural}>
                        <Form.Label>{plural}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            onChange={action(
                                ((i: number): ((e: any) => void) => {
                                    return (e) => {
                                        this.props.translation[plurals[i]] = e.target.value;
                                    };
                                })(i)
                            )}
                            value={this.props.translation[plurals[i]]}
                        />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Check
                            type={"checkbox"}
                            label={`Set this translation to null`}
                            value={this.props.toClear[i]}
                            onChange={action(
                                ((i) => (e) => {
                                    this.props.toClear[i] = e.target.checked;
                                })(i)
                            )}
                        />
                    </Form.Group>
                </>
            );
            i++;
        }

        return (
            <>
                <p>{this.props.translation.srcSingular}</p>

                {translators}
            </>
        );
    }
}
